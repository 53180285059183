.root {
  background: #061a72;
  color: #fafafa;
  bottom: 0;
  min-height: 20vh;
  margin-top: 5%;
  padding: 1rem;
}

.logo {
  width: 70%;
  max-width: 250px;
}


.socialIcon {
  margin-right: 0.5rem;
}
